@import '../../theme/universal.scss';

.contact {
  border: 1px solid $primary;
  border-radius: $border-radius;
  background-color: $primary;
  padding: 2px;
  color: black;
  position: absolute;
  bottom: 5vh;
  left: 2vh;
  z-index: 5;
  pointer-events: all;
}

.contact-mobile {
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 2px $pad-md;
  color: black;
  z-index: 5;
  pointer-events: all;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: black;
  :hover {
    color: white;
  }
}

.contact-name {
  margin-right: auto;
}
