@import '../theme/universal.scss';

.tab-view {
  flex-grow: 2;
  background-color: $secondary;
  overflow: hidden;
  min-height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.tab-view__borders {
  flex-grow: 1;
  min-width: $pad-lg;
  height: inherit;
}

.tab-view__wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
  width: 100%;
}

.tab-view__header {
  display: relative;
}

.tab-view__title {
  position: absolute;
  color: $secondary;
  font-size: 1.5 * $header-size;
  bottom: 5%;
  right: 5%;
}

.tab-view__image {
  height: 325px;
  width: 100%;
  border: 2px solid $secondary;
  border-radius: $border-radius;
  overflow: hidden;
  opacity: 0.9;
}

.mobile-header {
  height: 40px;
  width: 100%;
  background-color: $primary;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.view-wrapper {
  position: relative;
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.view-background {
  background-image: url('../assets/background.png');
  background-repeat: repeat-y;
}

.bio::before {
  content: '';
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.9;
}

.bio-image {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background-image: url('../assets/resume_photos/forest-thin.jpg');
  background-size: cover;
  height: 100vh;
}

.about-image {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  background-image: url('../assets/resume_photos/about-me-thin.jpg');
  background-size: cover;
  height: 100vh;
}

.about-description {
  width: 100%;
  height: 100%;
}

.bio-description {
  width: 75%;
  height: 100%;
}

.bio-description__body {
  margin: $pad-md;
  overflow: hidden;
  width: 75vw;
}

.bio-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 10px 5px;
  background-color: $primary;
  margin-top: auto;
}

.bio-header {
  display: flex;
  flex-direction: row;
}

.bio-points {
  color: $accent;
  margin-top: $pad-sm;
}

.tab-links {
  width: 100%;
  height: 100%;
  pointer-events: all;
}

.tab-name {
  color: white;
  font-size: 36px;
  text-align: end;
  padding: $pad-sm;
}

.tab-name__mobile {
  color: white;
  font-size: 24px;
  padding: $pad-sm;
  margin-right: auto;
  margin-left: $pad-sm;
}

.bio-welcome {
  width: 100%;
}

.bio-links {
  cursor: pointer;
  font-style: italic;
}

.bio-sub-details {
  font-size: 16px;
  color: $primary;
}

.tab-triangle {
  width: 15vw;
  height: 15vw;
  background-color: $primary;
  clip-path: polygon(0 100%, 0 0, 100% 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0.95;
  z-index: 1;
}

.tab-liner-mobile {
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  background-color: $primary;
  opacity: 0.95;
  z-index: 1;
  width: 100vw;
  height: 100px;
}

.tab-triangle__tablet {
  width: 25vw;
  height: 25vw;
}

.tab-triangle-top {
  width: 25vw;
  height: 25vw;
  background-color: $primary;
  position: absolute;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
  overflow: hidden;
  top: 0;
  bottom: unset;
  right: 0;
  left: unset;
  pointer-events: none;
  opacity: 0.95;
  z-index: 1;
}

.tab-triangle-top__tablet {
  width: 35vw;
  height: 35vw;
}

.contact-box {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-box__header {
  font-size: $sub-header-size;
  color: $accent;
  margin: $pad-sm 0 4px 4px;
  display: flex;
  flex-direction: row;
}

.contact-box__header-input {
  margin: $pad-sm 0;
}

.contact-box__body {
  width: 100%;
  color: $accent;
  border: solid 2px $primary;
  border-radius: $border-radius;
  width: 100%;
  height: 300px;
}

.contact-box__send {
  border: 2px solid $primary;
  border-radius: $border-radius;
  margin-left: $pad-lg;
  color: $accent;
  background-color: $secondary;
}

.contact-box__send:hover {
  color: white;
  background-color: $primary;
}

.contact-box__email {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  border: solid 2px $primary;
  border-radius: $border-radius;
  width: 50%;
  height: 60px;
}

.contact-box__confirm {
  border-radius: $border-radius;
  width: 100%;
}

.toolbar-options {
  color: $text-header;
  padding: $pad-sm;
  text-align: end;

  &:hover {
    cursor: pointer;
  }
}

.toolbar-options__selected {
  width: 100%;
  background-color: $secondary;
}

.view-spacer {
  margin-top: 6px;
}

.tab-view-summary__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
}

.tab-view-summary__body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: unset;
}

.mobile-wrapper {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/background.png');
  background-size: cover;
}

.mobile-text-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Simplifica';
  color: $primary;
  font-size: 2 * $header-size;
  text-align: center;
}
