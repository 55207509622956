//padding
$pad-sm: 12px;
$pad-md: 36px;
$pad-lg: 72px;

//colors
$primary: #86bbd8;
$secondary: #ffffff;
$accent: #2f4858;
$text-body: white;
$text-header: #0d1321;
$picture-background: rgb(73, 73, 73);

//text-size
$header-size: 28px;
$sub-header-size: 16px;

//border radius size
$border-radius: 8px;
$border-radius-image: 20px;

.icon {
  margin: 2px;
  cursor: pointer;
}

.view {
  width: 100%;
  overflow: hidden;
  height: 100vh;
}

.view-body__visited {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.view-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 0;
  opacity: 0;
  animation: appear;
  animation-duration: 5s;
  animation-delay: 13s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

.welcome {
  width: 100%;
  overflow: hidden;
  background-color: $secondary;
  height: 100vh;
  opacity: 1;
  animation: disappear;
  animation-duration: 5s;
  animation-delay: 13s;
  animation-fill-mode: forwards;
  ion-slides {
    height: 100%;
  }
}

.modal-wrapper {
  width: 90vw !important;
  min-height: 50vh !important;
}

@keyframes appear {
  to {
    height: 100vh;
    opacity: 1;
  }
}

@keyframes disappear {
  to {
    height: 0;
    opacity: 0;
  }
}

.mobile-contact {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
